<template>
  <div class="notice-container">
    <el-row>
      <el-col class="notice-left" :span="17">
        <div class="content">
          <div class="notice-breadcrumb">
            <div>首页</div>
            <div>></div>
            <div>公告</div>
            <div>></div>
            <div class="last-text">公告详情</div>
          </div>
          <div class="notice-title">团结防疫，人人有责！</div>
          <div class="notice-time">
            <div>发布时间： 2021/12/13 10:03:52</div>
            <div>浏览： 48次</div>
          </div>
          <div style="background-color: #F7F9FF;">
            <div class="notice-img-1">
              <img style="width: 100%;" src="./1.png" srcset="./1@1x.png 2x" alt="">
            </div>
            <div style="padding: 0 30px;">
              <p>
                根据国内疫情的发展，和近期气温变化较大，为了确保您的安全做到防患于未然，行政管理部温馨提示各位员工注意以下事项：
              </p>
              <div style="margin-top: 15px;">

                <div style="font-weight: bold;">一、疫情防护</div>
                <p style="margin-top: 15px;">
                  出门佩戴口罩、勤洗手、常通风，保持安全社交距离，养成文明健康生活习惯。非必要，不聚餐，不外出，不去涉疫地区，不信谣，不传谣。杭州市疫情形势严峻非必要不出入市区。积极配合当地疫情防控。
                </p>
              </div>
              <div style="margin-top: 15px;">

                <div style="font-weight: bold;">二、出行安全</div>
                <p style="margin-top: 15px;">
                  徒步上下班的员工，应行走在人行道内，切勿横穿马路。骑电动车时须安全正确佩戴好头盔，做到不逆行、不占用机动车道、不超速、后座不带人。开车时要注意文明驾驶，不酒后驾驶、不疲劳驾驶。严格遵守交通规则，避免交通事故的发生。
                </p>
              </div>
              <div style="margin-top: 15px;">

                <div style="font-weight: bold;">三、消防安全</div>
                <p style="margin-top: 15px;">
                  徒步上下班的员工，应行走在人行道内，切勿横穿马路。骑电动车时须安全正确佩戴好头盔，做到不逆行、不占用机动车道、不超速、后座不带人。开车时要注意文明驾驶，不酒后驾驶、不疲劳驾驶。严格遵守交通规则，避免交通事故的发生。
                  冬季天干物燥历来的是火灾多发高发期，要正确使用电器设备，不私拉乱接电源线和插座，不超负荷用电，禁止在非指定场所吸烟，使用明火。安全防火，人人有责。
                </p>
                <p style="margin-top: 50px;">祝：</p>
                <p>您及家人生活愉快，工作顺利，万事如意！</p>
              </div>
              <div class="btmflex">
                <div class="sonbtmflex">
                  <div>行政管理部</div>
                  <div>2021年12月13日</div>
                </div>
              </div>
            </div>

            <div style="height: 100px;">
            </div>

          </div>

          <div class="notice-footer">
            <div class="notice-prev">
              <router-link tag="span" to="/notice6">
                < 上一篇</router-link>
            </div>
            <div class="notice-next">
              <router-link tag="span" to="/notice4">下一篇 ></router-link>
            </div>
          </div>
        </div>
      </el-col>
      <el-col class="notice-right" :span="7">
        <div class="content">
          <div class="title">最新公告</div>
          <div>
            <div class="notice-other-1 pointer">
              <router-link tag="span" to="/notice14">童年，是一只载着无数快乐的小船；童年,……</router-link>
            </div>
            <div class="notice-other-time-1">2022-06-01 15:26</div>

            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice13">小鸟为天空而歌，蜜蜂为花朵而歌，今天……</router-link>
            </div>
            <div class="notice-other-time-2">2022-05-01 10:39</div>
            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice12">为助力健康亚运建设，保障劳动者职业健……</router-link>
            </div>
            <div class="notice-other-time-2">2022-04-26 16:52</div>
            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice11">2022年3月24日，杭州里德通信有限公司桐……</router-link>
            </div>
            <div class="notice-other-time-2">2022-04-25 11:26</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';

.notice-container {
  padding: 24px gap() 56px;
}

.notice-left {
  .content {
    background-color: #fff;
    margin-right: 12px;
    padding: 16px 32px 40px;
    font-size: 16px;
    .btmflex {
      height: 50px;
      margin-top: 60px;
      display: flex;
      justify-content: right;
      .sonbtmflex {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  p {
    text-indent: 2em;
    line-height: 1.5;
  }
}

.notice-breadcrumb {
  display: flex;
  font-size: 14px;
  margin-bottom: 20px;

  .last-text {
    color: #a6a6a6;
  }
}

.notice-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.notice-time {
  display: flex;
  color: #a6a6a6;
  font-size: 14px;
  padding-bottom: 8px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d4d4d4;

  div:first-of-type {
    margin-right: 32px;
  }
}

.notice-img-1,
.notice-img-2 {
  margin-top: 16px;
  margin-bottom: 32px;
  text-align: center;
}

.notice-img-3 {
  img {
    display: block;
    margin: auto;

    &:first-of-type {
      margin-top: 16px;
      margin-bottom: 24px;
    }

    &:last-of-type {
      margin-bottom: 32px;
    }
  }
}

.notice-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  margin-top: 64px;
  border-top: 1px solid #d4d4d4;
}

.notice-prev {
  color: #da261d;
  cursor: pointer;
}

.notice-next {
  color: #da261d;
  cursor: pointer;
}

.notice-right {
  .content {
    background-color: #fff;
    margin-right: 12px;
    padding: 24px 24px 400px;
    box-sizing: border-box;
    font-size: 16px;

    .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 18px;
    }
  }
}

.notice-other-time-1,
.notice-other-time-2 {
  padding: 8px 0;
  color: #a6a6a6;
  border-bottom: 1px solid #d4d4d4;
}

.notice-other-2 {
  margin-top: 32px;
}
</style>
